import Vue from 'vue'
import firebase from "firebase/app"
import "firebase/analytics";
import 'bulma/css/bulma.css'
import App from './App.vue'

firebase.initializeApp({
  apiKey: "AIzaSyAghlqYB4A2aRYuDpLgBF1BPDldLR_mtno",
  authDomain: "ezie-truck.firebaseapp.com",
  databaseURL: "https://ezie-truck.firebaseio.com",
  projectId: "ezie-truck",
  storageBucket: "ezie-truck.appspot.com",
  messagingSenderId: "922775630400",
  appId: "1:922775630400:web:b3dc7d72df038557afe5a5"
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
