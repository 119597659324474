<template>
  <div id="app">
    <div class="container">
      <article class="message is-danger" v-show="errorStr">
        <div class="message-header">
          <p>ผิดพลาด</p>
          <button class="delete" aria-label="delete"></button>
        </div>
        <div class="message-body">
          {{errorStr}}
        </div>
      </article>
      <Login @input="inputPhone" v-show="!isLoading && step == 0"/>
      <DriverMap :driver-list="drivers" v-if="step === 1" />
    </div>
  </div>
</template>

<script>
import Login from './components/Login.vue'
import DriverMap from './components/DriverMap.vue'

export default {
  name: 'App',
  components: {
    Login, DriverMap
  },
  data () {
    return {
      code: null,
      isLoading: false,
      customer: {},
      drivers: [],
      step: 0,
      errorStr: null
    }
  },
  methods: {
    inputPhone: function (val) {
      this.isLoading = true
      let vm = this
      fetch(`https://kfqo5wztbb.execute-api.ap-southeast-1.amazonaws.com/ezietruck/diary/customer/${val}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        vm.isLoading = false
        if (res.ok) {
          res.json().then((data) => {
            vm.code = val
            if (data.Items && data.Items.length > 0) {
              vm.customer = Object.assign({}, data.Items[0])
              vm.drivers = [...(new Set(data.Items[0].Name.split(',').slice(1)))]
              vm.step = 1
              vm.errorStr = null
            } else {
              vm.step = 0
              vm.errorStr = 'ไม่พบข้อมูลลูกค้า'
            }
          })
        } else {
          vm.errorStr = 'ติดต่อเชิฟเวอร์ไม่ได้'
        }
      }).catch((err) => {
        vm.isLoading = false
        vm.errorStr = JSON.stringify(err)
      })
    }
  }
}
</script>
