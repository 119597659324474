<template>
  <div>
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
        :google="google"
        :map="map"
      />
    </template>
  </div>
</template>
<script>
// import GoogleMapsApiLoader from 'google-maps-api-loader'
import { Loader } from '@googlemaps/js-api-loader';

export default {
  props: {
    mapConfig: Object,
    apiKey: String,
  },

  data() {
    return {
      google: null,
      map: null
    }
  },

  async mounted() {
    let vm = this
    const loader = new Loader({
      apiKey: this.apiKey
    });
    await loader
    .load()
    .then(() => {
      // new google.maps.Map(document.getElementById("map"), mapOptions);
      vm.google = window.google
      vm.initializeMap()
    })
    .catch(e => {
      console.error(e)
    });
    // const googleMapApi = await Loader({
    //   apiKey: this.apiKey
    // })
    // this.google = googleMapApi
  },

  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap
      this.map = new window.google.maps.Map(
        mapContainer, this.mapConfig
      )
    }
  }
}
</script>
<style scoped>
.google-map {
  width: 100%;
  min-height: 100%;
}
</style>