<template>
  <div class="columns">
    <div class="column">
      <GoogleMapLoader
        :mapConfig="mapConfig"
        apiKey="AIzaSyAghlqYB4A2aRYuDpLgBF1BPDldLR_mtno"
        :style="{'height': ($vssHeight - 20) + 'px', 'width': ($vssWidth*0.6) + 'px'}"
      >
      <template slot-scope="{ google, map }">
          <GoogleMapTruckMarker
          v-for="marker in markers"
          :key="marker.id"
          :marker="marker"
          :google="google"
          :map="map"
          :current-driver="currentDriver"
          :driver="driverObject[marker.id.split('#')[2]]"
          @click="handleMarkerClick(marker.id.split('#')[2])"
          :customerID="filterCustomerID"
        />
        <GoogleMapTruckPolyline :coordinates="historyCoordinates" :google="google" :map="map" />
        </template>
      </GoogleMapLoader>
    </div>
    <div class="column is-one-quarter">
      <nav class="navbar">
        <div class="navbar-brand">
          <img alt="Ezie logo" src="../assets/ezie_logo.png" />
        </div>
        <div class="navbar-menu">
          <div class="navbar-start">
            <div class="navbar-item">
              <div class="field">
                <label class="label">วันที่แสดงประวัติตำแหน่ง</label>
                <div class="control">
                  <date-picker v-model="selectedDate" type="date" :disabledDate="disabledDates" valueType="date" 
                    inputClass="input">
                  </date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div :style="{'height': ($vssHeight - 20) + 'px', 'overflow': 'auto'}">
        <div :class="{'box': true, 'has-background-primary': driver.DriverID === currentDriver.DriverID}" v-for="driver in driverItems" :key="driver.DriverID">
          <article class="media">
            <div class="media-left">
              <img alt="Truck" v-show="Date.now() - Date.parse(driver.LastGPSUpdatedAt || 0) > 5*60*1000" src="/gray_truck.png" />
              <img alt="Truck" v-show="Date.now() - Date.parse(driver.LastGPSUpdatedAt || 0) <= 5*60*1000" src="/truck.png" />
            </div>
            <div class="media-content">
              <div class="content">
                <p>
                  <a @click="handleMarkerClick(driver.DriverID)"><strong>{{driver.DriverName}}</strong></a><br />
                  ส่งข้อมูลล่าสุด {{dateFormat(driver.LastGPSUpdatedAt || '-')}} <br />
                  เบอร์โทร <a :href="'tel:+66' + driver.DriverPhoneNumber">{{driver.DriverPhoneNumber}}</a>
                </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMapLoader from "./GoogleMapLoader";
import GoogleMapTruckMarker from "./GoogleMapTruckMarker";
import GoogleMapTruckPolyline from "./GoogleMapTruckPolyline";
import { mapSettings } from "@/constants/mapSettings";
import VueScreenSize from 'vue-screen-size';
import firebase from "firebase/app";
import "firebase/firestore";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'DriverMap',
  props: {
    driverList: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data () {
    let now = new Date()
    return {
      drivers: [],
      locationItems: [],
      locationHistoryItems: [],
      currentDriver: {},
      selectedDate: now
    }
  },
  components: {
    GoogleMapLoader, GoogleMapTruckMarker, GoogleMapTruckPolyline, DatePicker
  },
  methods: {
    fetchDriver: function (val) {
      let vm = this
      fetch(`https://kfqo5wztbb.execute-api.ap-southeast-1.amazonaws.com/ezietruck/diary/mdriver`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({phoneNumbers: val})
      }).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            if (data && data.length > 0) {
              vm.drivers = data
              if (!vm.currentDriver.DriverID) {
                vm.currentDriver = vm.drivers[0]
              }
            }
          })
        }
      }).catch(console.error)
    },
    dateFormat (dateStr) {
      let d = new Date(Date.parse(dateStr))
      return d.toLocaleString()
    },
    handleMarkerClick: function (driverID) {
      this.locationHistoryItems = []
      this.fetchLocationHistory(driverID)
      if (driverID === this.currentDriver.DriverID) {
        this.currentDriver = {}
      } else {
        this.currentDriver = Object.assign({}, this.driverObject[driverID])
      }
    },
    fetchLocationHistory: function (driverID, lastEvaluatedKey) {
      let vm = this
      let q = ''
      if (lastEvaluatedKey) {
        q = `?lastEvaluatedKey=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}`
      } else {
        this.locationHistoryItems = []
      }
      fetch(`https://kfqo5wztbb.execute-api.ap-southeast-1.amazonaws.com/ezietruck/diary/locations/${driverID}${q}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            vm.locationHistoryItems = [...vm.locationHistoryItems, ...data.Items]
            if (data.LastEvaluatedKey) {
              vm.fetchLocationHistory(driverID, data.LastEvaluatedKey)
            }
          })
        }
      }).catch(console.error)
    },
    disabledDates: function (date) {
      let now = (new Date()).setHours(0,0,0,0)
      let startDate = (+now - 7*24*60*60*1000)
      let endDate = (+now + 24*60*60*1000)
      return +date <= startDate || +date >= endDate
    }
  },
  computed: {
    driverItems () {
      return [...this.drivers].sort((a, b) => (b.LastGPSUpdatedAt || '#').localeCompare((a.LastGPSUpdatedAt || '#')))
    },
    driverObject () {
      let out = {}
      for (let d of this.drivers) {
        out[d.DriverID] = d
      }
      return out
    },
    mapConfig() {
      let idx = this.drivers.findIndex((d) => d.DriverID === this.currentDriver)
      if (idx > -1 && this.drivers[idx].LastLocation) {
        return Object.assign({}, mapSettings, {
          center: {
            lat: this.drivers[idx].LastLocation.GPS.Lat,
            lng: this.drivers[idx].LastLocation.GPS.Lon
          },
          zoom: 11
        })
      } else {
        return {
          ...mapSettings,
          zoom: 11
        }
      }
    },
    markers () {
      return this.drivers.filter((d) => !!d.LastLocation).map((d) => {
        return {
          id: d.SK, 
          position: {lat: d.LastLocation.GPS.Lat, lng: d.LastLocation.GPS.Lon},
          lastUpdated: new Date(d.LastGPSUpdatedAt)
        }
      })
    },
    historyCoordinates () {
      if (this.currentDriver.SK) {
        let startDate = +((new Date(+this.selectedDate)).setHours(0,0,0,0))
        let endDate = +((new Date(+this.selectedDate)).setHours(23,59,59,999))
        return this.locationHistoryItems.filter((l) => Date.parse(l.UpdatedAt) > (Date.now() - 7*24*60*60*1000) && (startDate <= Date.parse(l.UpdatedAt) && Date.parse(l.UpdatedAt) <= endDate)).map((l) => {
          return {dt: new Date(Date.parse(l.UpdatedAt)), GPS: { lat: l.GPS.Lat, lng: l.GPS.Lon }, speed: l.GPS.Speed || 0} 
        })
      } else {
        return []
      }
    },
    filterCustomerID () {
      if (this.driverList.length > 1 && /^[A-Z]+$/.test(this.driverList[0])) {
        return this.driverList[0]
      }
      return null
    }
  },
  created () {
    let self = this
    self.fetchDriver(self.driverList.filter((l) => /^([0-9]+)$/.test(l)))
    const db = firebase.firestore();
    db.collection("locations").onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type !== "removed") {
          const data = change.doc.data()
          let idx = this.drivers.findIndex((d) => d.SK.split('#')[2] === data.SK.split('#')[1])
          if (this.drivers.length > 0 && idx > -1) {
            this.drivers = [
              ...this.drivers.slice(0, idx),
              Object.assign({}, this.drivers[idx], {
                LastLocation: {GPS: data.GPS},
                LastGPSUpdatedAt: (new Date(data.UpdatedAt.seconds * 1000)).toISOString()
              }),
              ...this.drivers.slice(idx + 1)
            ]
          }   
        }
      });
    });
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>