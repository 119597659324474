<template>
  <div class="hello">
    <img alt="Ezie logo" src="../assets/ezie_logo.png" />
    <form @submit.prevent="inputForm">
      <div class="field">
        <label class="label">รหัสเข้าระบบ</label>
        <div class="control">
          <input type="text" placeholder="รหัสเข้าระบบ" required :value="formValue" @input="validateForm($event.target.value)" :class="{'input': true, 'is-danger': errors['formValue']}" />
        </div>
        <p class="help is-danger" v-show="errors['formValue']">{{errors['formValue']}}</p>
      </div>
      <div class="field is-grouped">
        <div class="control">
          <input type="submit" class="button is-link" value="ต่อไป"/>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    value: String
  },
  data () {
    return {
      formValue: this.value
    }
  },
  methods: {
    validateForm: function (val) {
      this.formValue = val
    },
    inputForm: function () {
      if (!this.errors['formValue']) {
        this.$emit('input', this.formValue)
      }
    }
  },
  computed: {
    errors: function () {
      return {}
    }
  }
}
</script>