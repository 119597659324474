<script>
import moment from 'moment'

export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    marker: {
      type: Object,
      required: true
    },
    currentDriver: {
      type: Object
    },
    driver: {
      type: Object
    },
    customerID: {
      type: String
    }
  },
  data() {
    return {
      markerObj: null,
      infoObj: null,
      diaryItems: []
    }
  },
  methods: {
    fetchDiary: function (driverId) {
      let vm = this
      let d = moment()
      Promise.all([fetch(`https://kfqo5wztbb.execute-api.ap-southeast-1.amazonaws.com/ezietruck/diary/date/${driverId}/${d.format('YYYY-MM-DD')}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(res => res.json()),
      fetch(`https://kfqo5wztbb.execute-api.ap-southeast-1.amazonaws.com/ezietruck/diary/date/${driverId}/${d.subtract(1, 'days').format('YYYY-MM-DD')}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())]).then((results) => {
        let content = '<h1 class="title"> '+vm.driver.DriverName+' - บันทึกขนส่ง</h1><table class="table"><tr><th>สร้างเมื่อ</th><th>ลูกค้า</th><th>หยุด</th><th>บันทึก</th></tr>'
        for (let result of results) {
          if (result) {
            let items = result.Items.filter((d) => !vm.customerID || d.CustomerID === vm.customerID)
            vm.diaryItems = [...this.diaryItems, ...items]
            for (let item of items) {
              content += '<tr><td>'+ moment(item['CreatedAt']).format("lll") + '</td><td>' + (item.CustomerID || '') + '</td><td>' + (item.StopFor || '') + '</td><td>' + (item.Note || '') + '</td></tr>'
            }
          }
        }
        content += '</table>'
        vm.infoObj.setContent(content)
        vm.infoObj.open(vm.map, vm.markerObj)
      })
    }
  },
  watch: {
    currentDriver: function (val) {
      let icon = '/truck.png'
      if (val.SK === this.marker.id) {
        this.map.setCenter({lat: val.LastLocation.GPS.Lat, lng: val.LastLocation.GPS.Lon})
        this.fetchDiary(this.driver.DriverPhoneNumber)
        if (Date.now() - this.marker.lastUpdated > 5*60*1000) {
          icon = '/active_gray_truck.png'
        } else {
          icon = '/active_truck.png'
        }
      } else {
        if (Date.now() - this.marker.lastUpdated > 5*60*1000) {
          icon = '/gray_truck.png'
        }
        this.infoObj.close()
      }
      if (this.markerObj.getIcon() !== icon) {
        this.markerObj.setIcon(icon)
      }
    },
    marker: function (val) {
      let icon = '/truck.png'
      if (this.currentDriver.SK === this.marker.id) {
        this.map.setCenter(val.position)
        if (Date.now() - val.lastUpdated > 5*60*1000) {
          icon = '/active_gray_truck.png'
        } else {
          icon = '/active_truck.png'
        }
      } else {
        if (Date.now() - val.lastUpdated > 5*60*1000) {
          icon = '/gray_truck.png'
        }
      }
      this.markerObj.setIcon(icon)
      this.markerObj.setPosition(val.position)
    }
  },
  mounted() {
    const { Marker, InfoWindow } = this.google.maps;
    let icon = '/truck.png'
    if (this.currentDriver.SK === this.marker.id) {
      if (Date.now() - this.marker.lastUpdated > 5*60*1000) {
        icon = '/active_gray_truck.png'
      } else {
        icon = '/active_truck.png'
      }
    } else {
      if (Date.now() - this.marker.lastUpdated > 5*60*1000) {
        icon = '/gray_truck.png'
      }
    }
    this.markerObj = new Marker({
      title: this.marker.title,
      position: this.marker.position,
      marker: this.marker,
      map: this.map,
      icon: icon
    })
    this.infoObj = new InfoWindow({
      content: '',
    }) 
    let vm = this
    this.markerObj.addListener("click", () => {
      vm.$emit('click', vm.marker.id.split('#')[1])
      vm.fetchDiary(vm.driver.DriverPhoneNumber)
      vm.infoObj.open(vm.map, vm.markerObj)
    })
  },
  beforeDestroy () {
    this.markerObj.setMap(null);
  },
  render() {
    return null
  }
};
</script>
